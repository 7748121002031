import React from "react"
import Layout from "../components/layout"

import Header1 from "../components/header1";
import LoveFlutterHeader from "../assets/flutter/linkfive-loves-flutter-header.png";
import SubscriptionPlugin from "../assets/flutter/linkfive-subscription-flutter-plugin.png";
import ChangeSubscriptionOffer from "../assets/flutter/linkfive-change-flutter-subscription-offer.png";
import CustomizeUI from "../assets/flutter/linkfive-customize-your-flutter-ui.png";
import PaywallPluginUi from "../assets/flutter/flutter_paywal_ui_plugin.png";
import PubDevGroup from "../assets/flutter/pub-dev-logo.png";
import GifState from "../assets/flutter/flutter_linkfive_state_management_control.gif"
import {GatsbySeo} from "gatsby-plugin-next-seo/src/meta/gatsby-seo";
import BackgroundBox from "../components/blocks/background-box";
import SidebarSteps from "../components/tailblocks/sidebar-steps";
import userManagementImage from "../assets/landing/linkfive_user-management.png";

export default function Flutter() {
  return (
    <>
      <GatsbySeo title="LinkFive + Flutter = Easiest Integration of Flutter Subscriptions"
                 description="We love Flutter and we offer Flutter plugins to integrate your subscriptions into your Flutter app as easily as possible, including a customizable paywall."
      />
      <Layout utmSource="flutter" fullWave={false} bottomWave={false} callToAction={false}>
        <div className="py-24 bg-l6-background">
          <div
            className="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center lg:mb-20 lg:mt-12">
            <div
              className="md:ml-14 mb-10 md:mb-0 md:pt-16 md:max-w-2xl md:w-full flex flex-col items-start">
              <h1 className="text-4xl font-extrabold leading-10 tracking-tight sm:text-5xl sm:leading-none md:text-6xl">
                Easiest Integration of Subscriptions in Flutter
              </h1>

              <h2 className="mt-5 text-base sm:text-lg sm:max-w-xl md:text-xl md:mx-0">
                We love Flutter and we offer Flutter plugins to integrate your subscriptions into your
                Flutter app as easily as possible, including a customizable paywall.
              </h2>
              <h3 className="mb-5 mt-8 text-base sm:text-lg sm:max-w-xl md:text-xl md:mx-0">
                Subscriptions for Flutter are ready with just 4 lines of code.
              </h3>
              <div className="inline-block">
                <a
                  href="/flutter-blog/how-to-add-subscriptions-with-flutter-using-linkfive/"
                  className="action-button focus:shadow-outline focus:outline-none w-full sm:w-auto md:mt-5"
                >
                  Read our Complete Flutter Subscription Blog Post
                </a>
              </div>
            </div>

            <div className="w-full md:w-2/5 py-6 lg:ml-auto mx-auto xl:block hidden">
              <img alt="LinkFive loves Flutter"
                   title="LinkFive loves Flutter"
                   src={LoveFlutterHeader}/>
            </div>
          </div>
        </div>

        <SidebarSteps/>

        <section className="pb-8">
          <div className="container max-w-6xl mx-auto m-8 pt-0">
            <div className="flex flex-wrap ">
              <div className="sm:w-1/2 p-6">
                <h3 className="mt-4 mb-8 text-4xl font-medium leading-10 tracking-tight text-gray-800">
                  <span className="font-bold highlight">User Management</span> included
                </h3>
                <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-800">
                  All your users appear in the LinkFive user overview. If your users then decide to buy a subscription in your Flutter app, this is displayed in detail.
                </p>
                <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-800">
                  You can also manually assign your users a premium plan for a certain period of time.
                  Your users can even own and benefit from the same plan on multiple platforms.
                </p>
                <BackgroundBox>
                  <ul className="list-disc p-4">
                    <li key="1">Multi platform subscription plans</li>
                    <li key="2">Enable team subscriptions</li>
                    <li key="3">Family subscription plans are even possible</li>
                  </ul>
                </BackgroundBox>
              </div>
              <div className="w-full sm:w-1/2 p-12">
                <img alt="Improve your Subscriptions with LinkFive"
                     title="Improve your Subscriptions with LinkFive"
                     src={userManagementImage}/>
              </div>
            </div>
          </div>
        </section>

        <section className=" py-8">
          <div className="container max-w-6xl mx-auto m-8">
            <Header1
              headerText="Flutter Subscriptions Plugin Features"
              widthClass="w-4/6"
            />
            <div className="flex flex-wrap mt-20">
              <div className="sm:w-1/2 p-6">
                <h3 className="mt-2 mb-4 text-4xl font-extrabold leading-10 tracking-tight text-gray-800">
                  Dynamic subscription offer
                </h3>
                <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-800">
                  Our plugin will provide your app with the subscriptions you want to offer to your
                  users.
                  You can change your subscription offer in our UI and our plugin will update your
                  app.
                  A single method <b> .fetchSubscriptions()</b> is enough and you can offer your
                  subscription.
                </p>
                <h3 className="mt-8 mb-4 text-4xl font-extrabold leading-10 tracking-tight text-gray-800">
                  Make a purchase
                </h3>
                <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-800">
                  Our Flutter plugin takes care of everything related to a purchase. All you need to
                  do is
                  call
                  <b> .purchase( ... )</b> and we will do everything else for you.
                </p>
                <h3 className="mt-8 mb-4 text-4xl font-extrabold leading-10 tracking-tight text-gray-800">
                  Subscription receipt validation
                </h3>
                <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-800">
                  Our Flutter plugin checks if a purchase is genuine and not fake.
                </p>
                <h3 className="mt-8 mb-4 text-4xl font-extrabold leading-10 tracking-tight text-gray-800">
                  Get active subscriptions
                </h3>
                <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-800">
                  If your user has purchased your subscriptions, our plugin streams all the important
                  information your app needs to activate your user's premium status.
                </p>
              </div>
              <div className="w-full sm:w-1/2 ">
                <img alt="LinkFive plugin to integrate in your flutter app"
                     title="LinkFive plugin to integrate in your flutter app"
                     src={SubscriptionPlugin}/>
              </div>
            </div>
          </div>
        </section>
        <section className=" py-8">
          <div className="container max-w-6xl mx-auto m-8">
            <div className="flex flex-wrap mt-10">

              <div className="w-full sm:w-1/2 ">
                <img alt="LinkFive plugin to integrate in your flutter app"
                     title="LinkFive plugin to integrate in your flutter app"
                     src={ChangeSubscriptionOffer}/>
              </div>
              <div className="sm:w-1/2 p-6">
                <h3 className="mt-2 mb-4 text-4xl font-extrabold leading-10 tracking-tight text-gray-800">
                  Change your Subscription Offer
                </h3>
                <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-800">
                  With LinkFive it is possible to dynamically change your subscription offer. Manage
                  who
                  sees which subscriptions.
                </p>
                <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-800">
                  After you integrated our Flutter plugin,<b> no code is needed</b> to adjust your
                  offering. You could:
                </p>
                <ul className="list-disc max-w-2xl mt-4 ml-6 text-xl leading-7 text-gray-800">
                  <li key="1">Offer different subscriptions for different countries</li>
                  <li key="2">Offer different subscriptions for different App Versions</li>
                  <li key="3">Change global offering</li>
                </ul>
                <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-800">
                  Without any code changes!
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-l6-background text-white py-8">
          <div className="container max-w-6xl mx-auto m-8">
            <div className="flex flex-wrap mt-10">

              <div className="sm:w-1/2 p-6">
                <p className="mt-2 mb-4 text-4xl font-extrabold leading-10 tracking-tight text-gray-800">
                  Download LinkFive In App Purchases Plugin
                </p>
                <div className="inline-block">
                  <a
                    href="https://pub.dev/packages/linkfive_purchases" target="_blank"
                    rel="noopener"
                    className="action-button focus:shadow-outline focus:outline-none w-full sm:w-auto md:mt-5"
                  >
                    Get on Pub.dev
                  </a>
                  <div className="ml-4 inline-block">
                    <a
                      href="https://github.com/LinkFive/flutter-purchases-sdk" target="_blank"
                      rel="noopener"
                      className="action-button focus:shadow-outline focus:outline-none w-full sm:w-auto md:mt-5"
                    >
                      Check our Documentation
                    </a>
                  </div>
                </div>
              </div>
              <div className="w-full mt-8 sm:w-1/2 mx-auto ">
                <a href="https://pub.dev/packages/linkfive_purchases" rel="noopener"
                   target="_blank"><img
                  alt="LinkFive plugin to integrate in your flutter app"
                  title="LinkFive plugin to integrate in your flutter app"
                  src={PubDevGroup}/></a>
              </div>

            </div>
          </div>
        </section>


        <section className="pb-8 pt-24">
          <div className="container max-w-6xl mx-auto m-8">
            <Header1
              headerText="Customize your Flutter Subscription Paywall"
              widthClass="w-4/6"
            />
            <div className="flex flex-wrap mt-10">
              <div className="sm:w-1/2 p-6">
                <h3 className="mt-2 mb-4 text-4xl font-extrabold leading-10 tracking-tight text-gray-800">
                  Adjust your Paywall on the fly
                </h3>
                <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-800">
                  LinkFive gives you the ability to customize your subscription offer - even after
                  you've
                  published your app.
                </p>
                <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-800">
                  Find out which subscription promotion works best and highlight your bestseller!
                </p>
              </div>

              <div className="w-full sm:w-1/2 ">
                <img alt="LinkFive plugin to integrate in your flutter app"
                     title="LinkFive plugin to integrate in your flutter app"
                     src={CustomizeUI}/>
              </div>
            </div>
          </div>
        </section>

        <section className=" py-8">
          <div className="container max-w-6xl mx-auto m-8">
            <Header1
              headerText="Our Flutter Paywall UI Plugin"
              widthClass="w-4/6"
            />
            <div className="mt-0 p-6">
              <p className="mt-4 text-xl leading-7 text-gray-800 text-center mx-auto">
                We also help you with your own custom paywall. Our UI package offers a couple of designs
                which you can use in your app for free.
              </p>
            </div>
            <img className="mx-auto" alt="Flutter custom paywall package" src={PaywallPluginUi}/>
            <div className="flex mt-10 ">
              <div className="p-6">
                <h3 className="mt-2 mb-4 text-4xl font-extrabold leading-10 tracking-tight text-gray-800">
                  Seamless integration with our paywall UI plugin
                </h3>
                <p className="mt-4 text-xl leading-7 text-gray-800">
                  Integrating our Flutter UI plugin into your app is seamless and effortless. <br/>
                  The plugin works immediately together with our purchase plugin.<br/>
                  <b>Only one line of code is required</b> to display your subscriptions.
                </p>
                <p className=" mt-4 text-xl leading-7 text-gray-800">
                  <pre className="text-clip"><code className="md:text-sm text-xs  ">
                      SimplePaywall(<br/>
                    {" "}{" "}callbackInterface: linkFivePurchases,<br/>
                    {" "}{" "}// ...<br/>
                  });</code></pre>
                </p>
                <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-800">
                  That's it. The subscriptions are now ready for sale!
                </p>
              </div>
            </div>

            <div className="flex flex-wrap mt-20">
              <div className="sm:w-1/2 w-full p-6">
                <h3 className="mt-2 mb-4 text-4xl font-extrabold leading-10 tracking-tight text-gray-800">
                  Managing the Purchase State
                </h3>
                <p className="mt-4 text-xl leading-7 text-gray-800">
                  The plugin can also manage the UI state of the paywall.
                </p>
                <p className="mt-4 text-xl leading-7 text-gray-800">
                  You can always use the same UI page for your subscriptions. We make sure that either
                  the success page is displayed if the user has purchased Premium, or the paywall if
                  the user has not yet purchased.
                </p>

                <p className="mt-4 text-xl leading-7 text-gray-800">
                                <pre>
                                    <code className="md:text-sm text-xs">
                                        // no additional code needed<br/>
                                        // you can still add comments if you like, tho
                                    </code>
                                </pre>
                </p>
              </div>
              <div className="sm:w-1/2 w-full">
                <img className="mx-auto" style={{maxHeight: 400}} alt="Flutter custom paywall package"
                     src={GifState}/>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-l6-background text-white py-8">
          <div className="container max-w-6xl mx-auto m-8">
            <div className="flex flex-wrap mt-10">

              <div className="sm:w-1/2 p-6">
                <p className="mt-2 mb-4 text-4xl font-extrabold leading-10 tracking-tight text-gray-800">
                  Download our LinkFive UI Plugin
                </p>
                <div className="inline-block">
                  <a
                    href="https://pub.dev/packages/in_app_purchases_paywall_ui" target="_blank"
                    rel="noopener"
                    className="action-button focus:shadow-outline focus:outline-none w-full sm:w-auto md:mt-5"
                  >
                    Get on Pub.dev
                  </a>
                  <div className="ml-4 inline-block">
                    <a
                      href="https://github.com/LinkFive/flutter-in_app_purchases_paywall/tree/master/packages/in_app_purchases_paywall_ui"
                      target="_blank" rel="noopener"
                      className="action-button focus:shadow-outline focus:outline-none w-full sm:w-auto md:mt-5"
                    >
                      Check our Documentation
                    </a>
                  </div>
                </div>
              </div>
              <div className="w-full mt-8 sm:w-1/2 mx-auto ">
                <a href="https://pub.dev/packages/in_app_purchases_paywall_ui" rel="noopener"
                   target="_blank"><img
                  alt="LinkFive plugin to integrate in your flutter app"
                  title="LinkFive plugin to integrate in your flutter app"
                  src={PubDevGroup}/></a>
              </div>

            </div>
          </div>
        </section>


        <section className=" py-8">
          <div className="container max-w-6xl mx-auto m-8">
            <Header1
              headerText="Flutter Provider Plugin"
              widthClass="w-4/6"
            />
            <div className="mt-0 p-6">
              <p className="mt-4 text-xl leading-7 text-gray-800 text-center mx-auto">
                We created a Flutter provider plugin to make it even easier to add subscriptions to your app!
              </p>
            </div>
            <div className="flex mt-10 ">
              <div className="p-6">
                <h3 className="mt-2 mb-4 text-4xl font-extrabold leading-10 tracking-tight text-gray-800">
                  Register LinkFiveProvider.
                </h3>
                <p className="mt-4 text-xl leading-7 text-gray-800">
                  The Provider can be found <a href="https://pub.dev/packages/linkfive_purchases_provider"
                                               rel="noopener">on pub.dev</a>.
                  The integration can be easily done by adding the LinkFiveProvider to your provider root including your
                  api key.
                </p>

                <p className=" mt-4 text-xl leading-7 text-gray-800">
                  <pre className="text-clip"><code className="md:text-sm text-xs">
                    MultiProvider(<br/>
                    {"  "}providers: [<br/>
                    {"    "}// ...<br/>
                    {"    "}ChangeNotifierProvider(<br/>
                    {"      "}create: (context) => <span className="text-blue-700">LinkFiveProvider(<span
                    className="text-green-700">"API_KEY"</span>)</span>,<br/>
                    {"      "}lazy: false,<br/>
                    {"    "}),<br/>
                    {"  "}]<br/>
                    )</code></pre>
                </p>
                <p className="mt-4 text-xl leading-7 text-gray-800">
                  The provider is now ready to use and loads all active subscriptions. Your App can access the provider
                  by consuming the provider like any other provider implementation in your app. To get a fully
                  functional paywall, use our paywall plugin and add the provider as a parameter like in the example
                  below.
                </p>
                <p className=" mt-4 text-xl leading-7 text-gray-800">
                                <pre className="text-clip"><code className="md:text-sm text-xs">
                                  Consumer{"<"}<span className="text-blue-700">LinkFiveProvider</span>{">"}( <br/>
                                  {"  builder: (_, provider, __) {"}<br/>
                                  {"    "}return PaywallScaffold(<br/>
                                  {"      "}child: SimplePaywall(<br/>
                                  {"        "}callbackInterface: <span className="text-blue-700">provider</span><span
                                  className="text-green-700">.callbackInterface</span>,<br/>
                                  {"        "}subscriptionListData: <span className="text-blue-700">provider</span><span
                                  className="text-green-700">.paywallUIHelperData(context)</span>,<br/>
                                  {"        "}// ...<br/>
                                  {"    "}));<br/>
                                  {"}"});<br/></code></pre>
                </p>
                <p className="mt-4 text-xl leading-7 text-gray-800">
                  The plugin also loads, in combination with the SimplePaywall, all available subscriptions that you
                  want to offer if the user has not yet purchased a subscription. If you do not use the SimplePaywall,
                  make sure to call fetchSubscriptions if the user has no active subscriptions.
                </p>
                <p className="mt-4 text-xl leading-7 text-gray-800">
                  <a
                    href="/flutter-blog/subscriptions-in-flutter-the-complete-implementation-guide"
                    className="action-button focus:shadow-outline focus:outline-none w-full sm:w-auto md:mt-5"
                  >
                    Complete Flutter Subscriptions Implementation Guide
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className=" py-8">
          <div className="container max-w-6xl mx-auto m-8">
            <Header1
              headerText="Sign up on LinkFive Now. It's free!"
              widthClass="w-4/6"
            />

          </div>
        </section>
        <section>
          <div className="bg-l6-background px-15">
            <div
              className="max-w-5xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
              <h2
                className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl text-center pb-10 lg:pb-0">
                <span className="block ">Ready to get started? It's free!</span>
              </h2>

              <a
                href="//app.linkfive.io/sign-up?from=flutter"
                className="action-button focus:shadow-outline focus:outline-none"
              >
                Sign Up
              </a>
            </div>
          </div>
        </section>

      </Layout>
    </>)
}
