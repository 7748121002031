import React from "react"

import linkFiveCodeBlock from "/src/assets/landing/linkfive_code_block.png"
import HeaderH3asH2 from "../headerH3asH2";

const SidebarSteps = (props) => {
  return (
    <section className="text-gray-600 body-font max-w-6xl mx-auto">
      <div className="container px-5 py-24 mx-auto flex flex-wrap">
        <HeaderH3asH2
          headerText="How to add Subscriptions"
          widthClass="w-4/6"
        />
        <div className="flex flex-wrap w-full" style={{"overflow-x":"scroll"}}>
          <div className="lg:w-2/5 md:w-1/2 md:pr-10 md:py-6">
            <div className="flex relative pb-12">
              <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
              </div>
              <div
                className="flex-shrink-0 w-10 h-10 rounded-full bg-l6-secondary-variant inline-flex items-center justify-center text-white relative z-10">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                     className="w-5 h-5" viewBox="0 0 24 24">
                  <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                </svg>
              </div>
              <div className="flex-grow pl-4">
                <h4 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">1. Initialize
                  LinkFive</h4>
                <p className="mt-4 leading-relaxed">
                  <pre>
                    <code className="md:text-sm text-xs">
                      <span className="italic">// Get your API key after sign up</span>. <a
                      className="underline" href="/docs/flutter/initializing/">Docs</a><br/>
                        <b>LinkFivePurchases</b>.<span
                      className="text-blue-700">init(<span
                      className="text-green-700"><a
                      href="//app.linkfive.io/sign-up?from=api_key">"LinkFive Api Key"</a></span>)</span>;
                    </code>
                  </pre>
                </p>
              </div>
            </div>
            <div className="flex relative pb-12">
              <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
              </div>
              <div
                className="flex-shrink-0 w-10 h-10 rounded-full bg-l6-secondary-variant inline-flex items-center justify-center text-white relative z-10">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                     className="w-5 h-5" viewBox="0 0 24 24">
                  <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                </svg>
              </div>
              <div className="flex-grow pl-4">
                <h4 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">2. Get all
                  products</h4>
                <p className="mt-4 leading-relaxed"><pre>
                    <code className="md:text-sm text-xs">
                        <span className="italic">// Subscriptions to offer to the user</span>. <a
                      className="underline"
                      href="/docs/flutter/show-subscription-offer/">Docs</a> <br/>
                        <b>LinkFivePurchases</b>.<span
                      className="text-blue-700">products</span>;<br/>
                         <br/>
                        <span
                          className="italic">// Active subscriptions the user purchased</span>. <a
                      className="underline"
                      href="/docs/flutter/get-all-active-subscriptions/">Docs</a><br/>
                        <b>LinkFivePurchases</b>.<span
                      className="text-blue-700">activeProducts</span>;<br/>
                    </code>
                  </pre>
                </p>
              </div>
            </div>
            <div className="flex relative pb-12">
              <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
              </div>
              <div
                className="flex-shrink-0 w-10 h-10 rounded-full bg-l6-secondary-variant inline-flex items-center justify-center text-white relative z-10">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                     className="w-5 h-5" viewBox="0 0 24 24">
                  <circle cx="12" cy="5" r="3"></circle>
                  <path d="M12 22V8M5 12H2a10 10 0 0020 0h-3"></path>
                </svg>
              </div>
              <div className="flex-grow pl-4">
                <h4 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">3. Purchase the
                  product</h4>
                <p className="mt-4 leading-relaxed">
                  <pre>
                      <code className="md:text-sm text-xs">
                          <span
                            className="italic">// This handles the entire purchase process</span>. <a
                        className="underline" href="/docs/flutter/make-a-purchase/">Docs</a><br/>
                          <b>LinkFivePurchases</b>.<span className="text-blue-700">purchase(<span
                        className="text-green-700">productDetails</span>)</span>; <br/>
                      </code>
                  </pre>
                </p>
              </div>
            </div>
            <div className="flex relative">
              <div
                className="flex-shrink-0 w-10 h-10 rounded-full bg-l6-secondary-variant inline-flex items-center justify-center text-white relative z-10">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                     className="w-5 h-5" viewBox="0 0 24 24">
                  <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                  <path d="M22 4L12 14.01l-3-3"></path>
                </svg>
              </div>
              <div className="flex-grow pl-4">
                <h4 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">4. Enable Premium
                  Content</h4>
                <p className="mt-4 leading-relaxed">
                  <pre>
                      <code className="md:text-sm text-xs">
                          <span className="italic">// That's it</span><br/>
                          <span
                            className="italic">// It's time to show your premium content</span><br/>
                      </code>
                  </pre>
                </p>
              </div>
            </div>
          </div>
          <div className="lg:w-3/5">
            <img className="lg:w-2/3 object-cover object-center mx-auto rounded-lg mt-12"
                 src={linkFiveCodeBlock} alt="step"/>
          </div>

          <div className="p-6 w-full">
            <div className="block text-center">
              <div className="inline-block text-gray-700 mx-4">Read our</div>
              <div className="inline-block ">
                <a
                  href="/docs/flutter/get-started/" target="_blank"
                  className="action-button focus:shadow-outline focus:outline-none w-full sm:w-auto md:mt-5 mx-auto"
                >
                  Docs
                </a>
              </div>
              <div className="inline-block text-gray-700 mx-4">and</div>
              <div className="inline-block ">
                <a
                  href="//app.linkfive.io/sign-up?from=flutter-howto"
                  className="action-button focus:shadow-outline focus:outline-none w-full sm:w-auto md:mt-5 mx-auto"
                >
                  Start for Free
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>);
}

export default SidebarSteps;